import axios, { handleRefreshToken } from './base'

//#region AUTHENTICATION API

/**
 * Login
 * @param {{
  userName: string;
  password: string;
}} data
*/
export const loginAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, { __auth: false })
}

export const healthCheckAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/health-check-patient`)
}

export const registerAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/register-clinic?lang=${lang}`, data, { __auth: false })
}

export const verifyEmailAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-clinic-user`, data, { __auth: false })
}

export const resendConfirmationAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/resend-confirmation?lang=${lang}`, data, { __auth: false })
}

export const getUserInfoAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/users/me`)
}

export const updateUserAPI = async data => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/me`, data)
}

export const updatePasswordAPI = async data => {
  return axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, data)
}

export const forgotPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password?lang=${lang}`, data)
}

export const resetPasswordAPI = async (data, lang) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password?lang=${lang}`, data)
}

export const refreshToken = async () => {
  return handleRefreshToken()
}

//#endregion

//#region GENERAL CLINIC SETTING API

export const getClinicGeneralSettingsAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/general-settings`, { xScreenId, xFeatureId })
}

/**
 * Update Clinic's General Settings
 * @param {{
 *   clinicName: string;
 *   allowBookingInDays: number;
 * }} data
 */
export const updateClinicGeneralSettingsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinics/general-settings`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region  *LOCATION API

export const getLocationsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/locations?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createLocationsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/locations`, data, { xScreenId, xFeatureId })
}

export const getLocationsIdAPI = async (locationId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, { xScreenId, xFeatureId })
}

export const updateLocationsAPI = async (locationId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, data, { xScreenId, xFeatureId })
}

export const deleteLocationsAPI = async (locationId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/locations/${locationId}`, { xScreenId, xFeatureId })
}

export const updateLocationsEnableAPI = async (locationId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/locations/${locationId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region  *SPECIALTY API

export const getSpecialtyAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/specialties?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getAllSpecialtyAPI = async (xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getSpecialtyAPI(100, pageNumber, '', xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllSpecialtyAPI(xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const createSpecialtyAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/specialties`, data, { xScreenId, xFeatureId })
}

export const getSpecialtiesIdAPI = async (specialtyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/specialties/${specialtyId}`, { xScreenId, xFeatureId })
}

export const updateSpecialtiesAPI = async (specialtyId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/specialties/${specialtyId}`, data, { xScreenId, xFeatureId })
}

export const deleteSpecialtiesAPI = async (specialtyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/specialties/${specialtyId}`, { xScreenId, xFeatureId })
}

export const updateSpecialtiesEnableAPI = async (specialtyId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/specialties/${specialtyId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region  *SYMPTOM API
export const getSymptomsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/symptoms?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getSymptomByIdAPI = async (symptomId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/symptoms/${symptomId}`, { xScreenId, xFeatureId })
}

export const createSymptomsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/symptoms`, data, { xScreenId, xFeatureId })
}

export const updateSymptomAPI = async (symptomId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/symptoms/${symptomId}`, data, { xScreenId, xFeatureId })
}

export const deleteSymptomAPI = async (symptomId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/symptoms/${symptomId}`, { xScreenId, xFeatureId })
}

export const updateSymptomEnableAPI = async (symptomId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/symptoms/${symptomId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region  *DOCTOR API

export const getDoctorsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/doctors?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getDoctorByIdAPI = async (doctorId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}`, { xScreenId, xFeatureId })
}

export const createDoctorAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/doctors`, data, { xScreenId, xFeatureId })
}

export const updateDoctorAPI = async (doctorId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}`, data, { xScreenId, xFeatureId })
}

export const deleteDoctorAPI = async (doctorId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}`, { xScreenId, xFeatureId })
}

export const updateDoctorEnableAPI = async (doctorId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/doctors/${doctorId}/set-enabled`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region  * CLINIC INFO
export const getClinicInfoAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinics/info`, { xScreenId, xFeatureId })
}

//* Doctor Schedule
/**
 * Create new schedule
 * @param {{
 *  "doctorId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
 *  "locationId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
 *  "startDateTimeUnix": 0,
 *  "endDateTimeUnix": 0,
 *  "recurrenceString": "string"
 * }} data
 */
export const createDoctorScheduleAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules`, data, { xScreenId, xFeatureId })
}

/**
 * Get schedule detail
 * dayily : "RRULE:FREQ=DAILY;INTERVAL=1"
 * WeeklyOnDayOfWeek:"RRULE:FREQ=WEEKLY;INTERVAL=1"
 * EveryWorkDaysOfWeek: 'RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR'
 * Custom: "RRULE:FREQ=MONTHLY;INTERVAL=2"
 */

export const getScheduleByIdAPI = async (scheduleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}`, { xScreenId, xFeatureId })
}
/**
 * Search doctor schedule
 * @param {{
  "keyword": "string",
  "locationId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "specialtyId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "doctorId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "fromDate": 0,
  "toDate": 0
}} filter 
 */
export const searchDoctorScheduleAPI = async (filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules/search`, filter, { xScreenId, xFeatureId })
}

export const updateDoctorScheduleAPI = async (scheduleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}`, data, { xScreenId, xFeatureId })
}

export const deleteDoctorScheduleAPI = async (scheduleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/schedules/${scheduleId}/delete`, data, { xScreenId, xFeatureId })
}
export const getAppointmentByScheduleIdAPI = async (
  scheduleId,
  occurrenceStart,
  occurrenceEnd,
  xScreenId,
  xFeatureId
) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/schedules/${scheduleId}/get-appointments?occurrenceStart=${occurrenceStart}&occurrenceEnd=${occurrenceEnd}`,
    { xScreenId, xFeatureId }
  )
}
//#endregion

//#region  *CLINIC-PATIENT
export const searchClinicPatientsAPI = async (
  pageSize,
  pageNumber,
  orderBy,
  orderByType,
  filter,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-patients/search?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&orderByType=${orderByType}`,
    filter,
    { xScreenId, xFeatureId }
  )
}
export const searchAllClinicPatientsAPI = async (
  filter,
  xScreenId = 0,
  xFeatureId = 0,
  pageNumber = 1,
  orderBy = '',
  orderByType = 0
) => {
  const arr = []
  const apiRes = await searchClinicPatientsAPI(100, pageNumber, orderBy, orderByType, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllClinicPatientsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const deleteClinicPatientAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, { xScreenId, xFeatureId })
}

export const createClinicPatientAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-patients`, data, { xScreenId, xFeatureId })
}

export const getClinicPatientByIdAPI = async (clinicPatientId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, { xScreenId, xFeatureId })
}

export const updateClinicPatientAPI = async (clinicPatientId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-patients/${clinicPatientId}`, data, {
    xScreenId,
    xFeatureId
  })
}

/**
 * Merge multiple patients to one
 * @param {{ "clinicPatientIds": [ "guid" ] }} data
 * @param {*} xScreenId
 * @param {*} xFeatureId
 */
export const mergeClinicPatientsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-patients/merge`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region  *CLINIC-APPOINTMENT
export const searchClinicAppointmentsAPI = async (
  pageSize,
  pageNumber,
  filter,
  orderBy = '',
  orderType = 1,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/clinic-appointments/search?pageSize=${pageSize}&pageNumber=${pageNumber}&orderBy=${orderBy}&OrderByType=${orderType}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllClinicAppointmentsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchClinicAppointmentsAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllClinicAppointmentsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const getHistoryByPatientAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/get-by-patient`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAppointmentHistoryByPatientIdAPI = async (
  { clinicPatientId, pageSize = 20, pageNumber = 1 },
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/get-by-patient-v2`, {
    xScreenId,
    xFeatureId,
    params: {
      clinicPatientId,
      pageSize,
      pageNumber
    }
  })
}

export const getPrescriptionDetailByAppointmentId = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/prescription`, {
    xScreenId,
    xFeatureId
  })
}

export const getScheduleSlotsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/get-schedule-slots`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getClinicAppointmentByIdAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}`, { xScreenId, xFeatureId })
}

export const createClinicAppointmentAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments`, data, { xScreenId, xFeatureId })
}

export const updateClinicAppointmentAPI = (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getClinicAppointmentByIdForEditAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/details-for-edit`, {
    xScreenId,
    xFeatureId
  })
}

export const getClinicAppointmentsHasInvalid = (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/has-invalid`, { xScreenId, xFeatureId })
}

export const approveAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/approve`, data, { xScreenId, xFeatureId })
}

export const checkInAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/check-in`, {
    xScreenId,
    xFeatureId
  })
}

export const cancelAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/cancel`, data, { xScreenId, xFeatureId })
}

export const getNextAppointmentAPI = (doctorId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/next?doctorId=${doctorId}`, {
    xScreenId,
    xFeatureId
  })
}

export const getMedicalInfoAPI = (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/medical`, {
    xScreenId,
    xFeatureId
  })
}

export const getCountAppointmentsAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/search-count`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAllAppointmentByStatusAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/all-by-status`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getAllTodayAppointmentByStatusAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-appointments/all-today`, data, { xScreenId, xFeatureId })
}

//#endregion

//#region *Clinic-Queue TODO: Need to remove cause clinic-queue is not used anymore

export const changeAppointmentDoctorAPI = async (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/change-doctor`, data, {
    xScreenId,
    xFeatureId
  })
}

export const startExaminationAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/start-exam`, {
    xScreenId,
    xFeatureId
  })
}

export const finishExaminationAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/finish-exam`, {
    xScreenId,
    xFeatureId
  })
}

export const getPackageAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/packages`, {
    xScreenId,
    xFeatureId
  })
}

//#endregion

//#region  *Notifications

export const updateDeviceTokenAPI = data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/users/register-device`, data)
}

export const markANotificationAsReadAPI = notificationId => {
  return axios.put(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/read`)
}

export const markAllNotificationAsReadAPI = () => {
  return axios.put(`${process.env.REACT_APP_API_URL}/notifications/read-all`)
}

export const getNotificationsAPI = (pageSize, pageNumber, lang) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/notifications?lang=${lang}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  )
}

export const deleteNotificationsAPI = notificationId => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}`)
}

export const getCountOfUnreadNotiAPI = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread-count`)
}
//#endregion

//#region  * Roles

export const getRolesAPI = (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/roles?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createRolesAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/roles`, data, { xScreenId, xFeatureId })
}

export const updateRolesAPI = (roleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, data, { xScreenId, xFeatureId })
}

export const deleteRolesAPI = (roleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, { xScreenId, xFeatureId })
}

export const getRolesDetailAPI = (roleId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/roles/${roleId}`, { xScreenId, xFeatureId })
}

export const updateRolesEnableAPI = (roleId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/roles/${roleId}/set-enabled`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region  USER ACCOUNTS API

export const getUserAccountsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/employees?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getAllUserAccountsAPI = async (keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getUserAccountsAPI(100, pageNumber, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllUserAccountsAPI(keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const getUserAccountByIdAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, { xScreenId, xFeatureId })
}

export const inviteUserAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/employees/invite`, data, { xScreenId, xFeatureId })
}

export const updateUserAccountAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, data, { xScreenId, xFeatureId })
}

export const deleteUserAccountAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/employees/${employeeId}`, { xScreenId, xFeatureId })
}

export const updateUserEnableAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const resetUserPasswordAPI = async (employeeId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/change-password`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getUserPermissionAPI = async () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/employees/my-permissions`)
}

export const reinviteUserAPI = async (employeeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/re-invite`, {
    xScreenId,
    xFeatureId
  })
}

export const changeClinicOwnerAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinics/change-owner`, data, {
    xScreenId,
    xFeatureId
  })
}
//#endregion

//#region  * Groups

export const getUserGroupsAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/user-groups?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const createUserGroupAPI = (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user-groups`, data, { xScreenId, xFeatureId })
}

export const updateUserGroupAPI = (userGroupId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, data, { xScreenId, xFeatureId })
}

export const deleteUserGroupAPI = (userGroupId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, { xScreenId, xFeatureId })
}

export const updateUserGroupEnableAPI = (userGroupId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getUserGroupDetailAPI = (userGroupId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/user-groups/${userGroupId}`, { xScreenId, xFeatureId })
}
//#endregion

//#region  * Upload Image

export const createPhysicalFileAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/request-upload-file`, data)
}
export const putUploadImageAPI = async (preSignedURL, data) => {
  return axios.put(`${preSignedURL}`, data, {
    __auth: false,
    upload: data.type
  })
}
export const putUploadDoneAPI = async fileId => {
  return axios.put(`${process.env.REACT_APP_API_URL}/files/${fileId}/upload-done`)
}

export const getFileUrlsAPI = async data => {
  return axios.post(`${process.env.REACT_APP_API_URL}/files/get-urls`, data)
}

export const getFileFromUrlAPI = async url => {
  return axios.get(url, { __auth: false, responseType: 'blob' })
}

export const convertDocxToPdfAPI = async data => {
  return axios.post(`https://pdf.drcloud.vn/forms/libreoffice/convert`, data, {
    upload: 'multipart/form-data',
    responseType: 'blob'
  })
}

//#endregion

//#region  * Properties

export const getPropertiesAPI = async (pageSize, pageNumber, keyword, entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/properties/search?pageSize=${pageSize}&pageNumber=${pageNumber}&entityTypeId=${entityTypeId}&keyword=${encodeURI(
      keyword
    )}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const getAllPropertiesAPI = async (entityTypeId, keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getPropertiesAPI(100, pageNumber, keyword, entityTypeId, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllPropertiesAPI(entityTypeId, keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const createPropertyAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/properties`, data, { xScreenId, xFeatureId })
}

export const updatePropertyAPI = async (propertyId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, data, { xScreenId, xFeatureId })
}

export const getPropertyByIdAPI = async (propertyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, { xScreenId, xFeatureId })
}

export const deletePropertyAPI = async (propertyId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/properties/${propertyId}`, { xScreenId, xFeatureId })
}
//#endregion

// #region *Forms

export const getFormsAPI = async (pageSize, pageNumber, entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/forms?pageSize=${pageSize}&pageNumber=${pageNumber}&entityTypeId=${entityTypeId}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const createFormAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/forms`, data, { xScreenId, xFeatureId })
}

export const updateFormAPI = async (formId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/forms/${formId}`, data, { xScreenId, xFeatureId })
}

export const getFormByIdAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/${formId}`, { xScreenId, xFeatureId })
}

export const deleteFormAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/forms/${formId}`, { xScreenId, xFeatureId })
}

export const updateEnableFormAPI = async (formId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/forms/${formId}/set-enabled`, data, { xScreenId, xFeatureId })
}

export const getAllFormsByEntityTypeAPI = async (entityTypeId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/all-by-entity-type?entityTypeId=${entityTypeId}`, {
    xScreenId,
    xFeatureId
  })
}

export const getFormPropertiesAPI = async (formId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/forms/${formId}/properties`, { xScreenId, xFeatureId })
}

// #endregion

//#region  * Medicines

export const getMedicinesAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/medicines?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const getAllMedicinesAPI = async (keyword, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getMedicinesAPI(100, pageNumber, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > apiRes.data.paging.pageSize + (apiRes.data.paging.pageNumber - 1) * 99) {
      const nextPageRes = await getAllMedicinesAPI(keyword, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const createMedicineAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/medicines`, data, { xScreenId, xFeatureId })
}

export const deleteMedicineAPI = async (medicineId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/medicines/${medicineId}`, { xScreenId, xFeatureId })
}

export const updateMedicineAPI = async (medicineId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/medicines/${medicineId}`, data, { xScreenId, xFeatureId })
}

export const getMedicineLotsAPI = async (medicineId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/medicines/${medicineId}/medicine-lots`, { xScreenId, xFeatureId })
}
//#endregion

//#region *Prescription

export const createPrescriptionAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/prescriptions`, data, { xScreenId, xFeatureId })
}
//#endregion

//#region *Packages

export const searchPackagesAPI = async (
  pageSize,
  pageNumber,
  keyword = '',
  filter = {},
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllPackagesAPI = async (keyword = '', pageNumber = 1) => {
  const arr = []
  const apiRes = await searchPackagesAPI(100, pageNumber, keyword, {})
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllPackagesAPI(keyword, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const createPackageAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/packages`, data, { xScreenId, xFeatureId })
}

export const updatePackageAPI = async (packageId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}`, data, { xScreenId, xFeatureId })
}

export const updatePackageEnableAPI = async (packageId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getPackageDetailsAPI = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/packages/${packageId}`, { xScreenId, xFeatureId })
}

export const setAsDraftPackageAPI = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}/set-as-draft`, { xScreenId, xFeatureId })
}

export const submitForApprovePackageAPI = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}/submit-for-approve`, {
    xScreenId,
    xFeatureId
  })
}

export const deletePackageAPI = async (packageId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/packages/${packageId}`, { xScreenId, xFeatureId })
}

//#endregion

//#region *Services

export const getServicesAPI = async (pageSize, pageNumber, keyword = '', xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/services?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${encodeURI(
      keyword
    )}`,
    { xScreenId, xFeatureId }
  )
}

export const searchAllServicesAPI = async (keyword = '', pageNumber = 1) => {
  const arr = []
  const apiRes = await getServicesAPI(100, pageNumber, keyword)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllServicesAPI(keyword, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const createServiceAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/services`, data, { xScreenId, xFeatureId })
}

export const updateServiceAPI = async (serviceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/services/${serviceId}`, data, { xScreenId, xFeatureId })
}

export const getServiceByIdAPI = async (serviceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/services/${serviceId}`, { xScreenId, xFeatureId })
}

export const updateServicesEnableAPI = async (serviceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/services/${serviceId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getServicesOfAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/services`, {
    xScreenId,
    xFeatureId
  })
}

export const updateServicesOfAppointmentAPI = async (appointmentId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/services`, data, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

// #region *Check-In Form

export const createCheckInAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/check-in-sheets`, data, { xScreenId, xFeatureId })
}

export const getCheckInSheetAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/check-in-sheet`, {
    xScreenId,
    xFeatureId
  })
}

// #endreion

// #region *Service Sheet

export const createServiceSheetsOfAppointmentAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/service-sheets`, data, { xScreenId, xFeatureId })
}

export const getServiceSheetsAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/service-sheets`, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

// #region *Purchased package

export const searchPurchasedPackagesAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/orders/packages/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllPurchasedPackagesAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchPurchasedPackagesAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllPurchasedPackagesAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const getPurchasedPackageDetail = async (orderPackageItemId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/packages/${orderPackageItemId}/details`, {
    xScreenId,
    xFeatureId
  })
}

export const getPurchasedPackageByAppointmentAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/purchased-packages`, {
    xScreenId,
    xFeatureId
  })
}

export const getPurchasedPackageByPatientAPI = async (patientId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/clinic-patients/${patientId}/purchased-packages`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getPurchasedPackageHistoryByPurchasedPackageId = async (
  orderPackageItemId,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/orders/packages/${orderPackageItemId}/usage-history`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region *Billing

export const startBillingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/start-billing`, {
    xScreenId,
    xFeatureId
  })
}

export const finishBillingAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/finish-billing`, {
    xScreenId,
    xFeatureId
  })
}

export const createInvoiceAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/invoices`, data, { xScreenId, xFeatureId })
}

export const payInvoiceAPI = async (invoiceId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/pay`, data, { xScreenId, xFeatureId })
}

export const checkServiceOnBillingAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/invoices/check-services-appointment`, data, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region *Bills Listing

export const searchInvoicesAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/invoices/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllInvoicesAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchInvoicesAPI(100, pageNumber, filter, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllInvoicesAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const getBillDetailAPI = async (invoiceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`, {
    xScreenId,
    xFeatureId
  })
}

export const getInvoicesAPI = async (appointmentId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/clinic-appointments/${appointmentId}/invoices`, {
    xScreenId,
    xFeatureId
  })
}

export const cancelInvoicesAPI = async (invoiceId, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/cancel`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region Presc template
export const createPrescriptionTemplateAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/prescription-templates`, data, { xScreenId, xFeatureId })
}

export const getPrescriptionTemplatesAPI = async (pageSize, pageNumber, keyword, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/prescription-templates?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const deletePrescriptionTemplateAPI = async (prescTemplateId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/prescription-templates/${prescTemplateId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updatePrescriptionTemplateEnableAPI = async (prescTemplateId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/prescription-templates/${prescTemplateId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getPrescriptionTemplateByIdAPI = async (prescTemplateId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/prescription-templates/${prescTemplateId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updatePrescriptionTemplateAPI = async (prescTemplateId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/prescription-templates/${prescTemplateId}`, data, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region * Service template
export const createServiceTemplate = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/service-templates`, data, {
    xScreenId,
    xFeatureId
  })
}
export const getServiceTemplatesAPI = async (pageSize, pageNumber, keyword, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/service-templates?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}
export const getServiceTemplateDetailsAPI = async (serviceTemplateId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/service-templates/${serviceTemplateId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateServiceTemplateAPI = async (serviceTemplateId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/service-templates/${serviceTemplateId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const updateServiceTemplateEnableAPI = async (serviceTemplateId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/service-templates/${serviceTemplateId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const deleteServiceTemplateAPI = async (serviceTemplateId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/service-templates/${serviceTemplateId}`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

// #region Suppliers
export const createSupplierAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/suppliers`, data, { xScreenId, xFeatureId })
}

export const getSuppliersAPI = async (pageSize, pageNumber, keyword, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/suppliers?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const deleteSupplierAPI = async (supplierId, xScreenId = 0, xFeatureId = 0) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateSupplierEnableAPI = async (supplierId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}/set-enabled`, data, {
    xScreenId,
    xFeatureId
  })
}

export const getSupplierByIdAPI = async (supplierId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, {
    xScreenId,
    xFeatureId
  })
}

export const updateSupplierAPI = async (supplierId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/suppliers/${supplierId}`, data, {
    xScreenId,
    xFeatureId
  })
}

export const searchAllSuppliersAPI = async (keyword, pageNumber = 1, xScreenId = 0, xFeatureId = 0) => {
  let arr = []
  const apiRes = await getSuppliersAPI(100, pageNumber, keyword, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSuppliersAPI(keyword, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

// #endregion

// #region * TTS

export const ttsAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/google-cloud/tts`, data, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

// #region * get supplier
export const searchSuppliersAPI = async (keyword, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/suppliers?pageSize=${pageSize}&pageNumber=${pageNumber}&keyword=${keyword}`,
    { xScreenId, xFeatureId }
  )
}
// #endregion

// #region * Stock
export const searchStockOnHandAPI = async (keyword, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/stock-sheets/stock-on-hand?keyword=${keyword}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    { xScreenId, xFeatureId }
  )
}

export const searchAllStockOnHandAPI = async (keyword, pageNumber = 1, xScreenId = 0, xFeatureId = 0) => {
  let arr = []
  const apiRes = await searchStockOnHandAPI(keyword, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllStockOnHandAPI(keyword, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getStockInfoByMedicineAPI = async (medicineId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/medicines/${medicineId}/stock-info`, {
    xScreenId,
    xFeatureId
  })
}

export const updateMedicineLotStatusAPI = async (medicineLotId, status, xScreenId = 0, xFeatureId = 0) => {
  return axios.put(`${process.env.REACT_APP_API_URL}/medicines/${medicineLotId}/set-enabled`, status, {
    xScreenId,
    xFeatureId
  })
}

export const getMedicineLotsByMedicineAPI = async (medicineId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/medicines/${medicineId}/medicine-lots`, {
    xScreenId,
    xFeatureId
  })
}

export const getStockHistoryByMedicineAPI = async (medicineId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stock-sheets/${medicineId}/history`, {
    xScreenId,
    xFeatureId
  })
}

export const checkStockQuantityByMedicineAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/medicines/check-stock-quantity`, data, {
    xScreenId,
    xFeatureId
  })
}

// #region stock sheets
export const createStockSheetAPI = async (data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/stock-sheets`, data, {
    xScreenId,
    xFeatureId
  })
}

export const searchStockSheetsAPI = async (pageSize, pageNumber, filter, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/stock-sheets/search?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    { xScreenId, xFeatureId }
  )
}

export const searchAllStockSheetAPI = async (keyword, pageNumber = 1, xScreenId = 0, xFeatureId = 0) => {
  let arr = []
  const apiRes = await searchStockSheetsAPI(keyword, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllStockSheetAPI(keyword, pageNumber + 1, xScreenId, xFeatureId)
      arr = [...arr, ...nextPageRes]
    }
  }
  return arr
}

export const getStockSheetDetailsAPI = async (stockSheetId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/stock-sheets/${stockSheetId}`, {
    xScreenId,
    xFeatureId
  })
}
// #endregion

// #region  reports
export const searchAppointmentReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/appointments?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchActivityLogReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/activity-logs?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}
export const searchAllActivityLogReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchActivityLogReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchActivityLogReportsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}
export const stockByMedicineReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/stock-by-medicines?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchAllStockByMedicineReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await stockByMedicineReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllStockByMedicineReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchSalesReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-report?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const stockByMedicineLotReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/stock-by-medicine-lot-report?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchAllStockByMedicineLotReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await stockByMedicineLotReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await stockByMedicineLotReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const saleByDoctorReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-by-doctor?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchAllSaleByDoctorReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await saleByDoctorReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSaleByDoctorReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchServiceReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-by-service?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}
export const searchSaleByPatientReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-by-patients?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchServicePackagesReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-by-service-packages?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchAllSaleByPackagesReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchServicePackagesReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSaleByPackagesReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchAllSaleByPatientReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchSaleByPatientReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSaleByPatientReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const getSaleByMedicineLotReportsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/reports/sale-by-medicine-lots?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

export const searchAllSaleByMedicineLotReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await getSaleByMedicineLotReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSaleByMedicineLotReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchAllSaleByServiceReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchServiceReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAllSaleByServiceReportsAPI(filter, xScreenId, xFeatureId, pageNumber + 1)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchAllAppointmentReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchAppointmentReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchAppointmentReportsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

export const searchAllSalesReportsAPI = async (filter, xScreenId = 0, xFeatureId = 0, pageNumber = 1) => {
  const arr = []
  const apiRes = await searchSalesReportsAPI(filter, 100, pageNumber, xScreenId, xFeatureId)
  if (apiRes && apiRes.data && apiRes.data.pageData && apiRes.data.pageData.length > 0) {
    arr.push(...apiRes.data.pageData)
    if (apiRes.data.paging.totalItem > 100) {
      const nextPageRes = await searchSalesReportsAPI(filter, pageNumber + 1, xScreenId, xFeatureId)
      arr.push(...nextPageRes)
    }
  }
  return arr
}

// #endregion

// #region Dashboard
export const getTodayStaticsAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/today-statics`, {
    xScreenId,
    xFeatureId
  })
}

export const getTopSalesMedicinesAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/top-sale-medicines`, {
    xScreenId,
    xFeatureId
  })
}

export const getTopSalesServicesAPI = async (xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/top-sale-services`, {
    xScreenId,
    xFeatureId
  })
}

// #endregion

//#region clinics setting Laboratories
export const getLaboratoryDetailsAPI = async (laboratoryId, xScreenId = 0, xFeatureId = 0) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/laboratories/${laboratoryId}`, {
    xScreenId,
    xFeatureId
  })
}

export const connectHealthcareAPI = async (laboratoryId, data, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/laboratories/${laboratoryId}`, data, {
    xScreenId,
    xFeatureId
  })
}

// #region Lab Result
export const searchLabResultsAPI = async (filter, pageSize, pageNumber, xScreenId = 0, xFeatureId = 0) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/lab-results?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

//#endregion

//#region  Re-examination

export const searchReExaminationAppointmentsAPI = async (
  filter,
  pageSize,
  pageNumber,
  xScreenId = 0,
  xFeatureId = 0
) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/re-examination-appointments?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    filter,
    {
      xScreenId,
      xFeatureId
    }
  )
}

//#endregion
